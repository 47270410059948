import { EventEmitter } from 'events'
import hotkeys from 'mousetrap'

const ee = new EventEmitter()

const keys = [
  'up',
  'down',
  'left',
  'right',
  'pagedown',
  'pageup',
  'home',
  'end',
  'enter',
  'esc',
  'space',
  'tab',
  'ctrl+c',
  'ctrl+C',
  'ctrl+d',
  'ctrl+f',
  'ctrl+n',
  'ctrl+p',
  'ctrl+r',
  'ctrl+R',
  'ctrl+s',
  'ctrl+S',
  'ctrl+u',
  'ctrl+j',
  'ctrl+k',
  'alt+up',
  'alt+down',
  'alt+left',
  'alt+right',
  'F1',
  'F5',
  '0',
  '1',
  '2',
  '7',
  'a',
  'b',
  'c',
  'C',
  'e',
  'E',
  'A',
  'f',
  'F',
  'g',
  'G',
  'h',
  'H',
  'j',
  'k',
  'l',
  'L',
  'm',
  'M',
  'n',
  'o',
  'O',
  'p',
  'P',
  'r',
  's',
  't',
  'u',
  'v',
  'q',
  'Q',
  '%',
  '$',
  '?',
  '/',
  ']',
  '[',
  '}',
  '{',
  '>',
  '<',
  '\\\\'
]

const aliases = {
  // hotkeys-js
  //'>': 'shift+.',
  //'<': 'shift+,',
  //'?': 'shift+/'
}

for (let key of keys) {
  let hk = aliases[key] || key

  if (hotkeys.bind) {
    hotkeys.bind(hk, (event: any) => {
      ee.emit(key, event, key)
    })
  }
}

export default ee
