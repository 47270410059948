import numeral from 'numeral'

const commafy = (value, decimals = 2) => {
  if (typeof decimals === 'string') {
    decimals = Number(decimals)
  }
  if (decimals === null) {
    decimals = 2
  }

  return numeral(value).format(`0,0.[${'0'.repeat(decimals)}]`)
}

export default commafy
