import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import commafy from 'src/utils/commafy'
import DateTime from 'luxon/src/datetime.js'
import styles from 'src/components/Home/Home.module.css'
import { priceDecimals } from 'src/config'
import useInterval from 'src/hooks/useInterval'

const downArrow = '▼'
const upArrow = '▲'
const blankChar = '⠀' // U+2800

const Pinwheel = () => {
  const [icon, setIcon] = useState<string>('-')
  let [idx, setIndex] = useState<number>(0)
  const chars = ['-', '\\', '|', '/']
  useInterval(() => {
    if (idx >= chars.length) {
      idx = 0
    }
    setIcon(chars[idx])
    setIndex(idx + 1)
  }, 100)
  return <span>{icon}</span>
}

const Table = (props: any) => {
  const {
    rows,
    cols,
    sortBy,
    sort,
    sortDesc,
    onRowClick,
    onRowDoubleClick,
    onColClick,
    activeRow,
    activeCol,
    fetching
  } = props
  const [hasMounted, setHasMounted] = useState<boolean>(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  const handleTableBodyClick = (event: any) => {
    let { target } = event
    let { tagName } = target
    if (tagName === 'TD' || tagName === 'TH') {
      target = target.parentNode
      tagName = target.tagName
    }
    if (tagName === 'TR') {
      onRowClick(target.rowIndex - 1)
    }
  }
  const handleTableHeadClick = (event: any) => {
    let { target } = event
    let { tagName } = target
    if (tagName === 'SPAN') {
      target = target.parentNode
      tagName = target.tagName
    }
    if (tagName === 'TH') {
      onColClick(target.cellIndex)
    }
  }
  const handleTableBodyDoubleClick = (event: any) => {
    let { target } = event
    let { tagName } = target
    if (tagName === 'TD' || tagName === 'TH') {
      target = target.parentNode
      tagName = target.tagName
    }
    if (tagName === 'TR') {
      onRowDoubleClick(target.rowIndex - 1)
    }
  }
  const isSelected = (col: string) => {
    return col === sortBy
  }
  const arrow = (col: string) => {
    if (isSelected(col)) {
      return sortDesc ? downArrow : upArrow
    }

    return blankChar
  }
  const color = (col: string) => {
    return col === sortBy ? styles.thHighlight : ''
  }
  const loading = fetching ? styles.loading : ''

  const width = (w: number) => {
    return `${w * 10}px`
  }

  const tableHeaders = [
    {
      name: 'Rank',
      className: classnames(styles.th, styles.thRank, color(cols[0])),
      title: 'Sort by rank',
      label: `${arrow(cols[0])}[r]ank`,
      selected: isSelected(cols[0]),
      width: width(9)
    },
    {
      name: 'Name',
      className: classnames(styles.th, styles.thName, color(cols[1])),
      title: 'Sort by name',
      label: `${arrow(cols[1])}[n]ame`,
      selected: isSelected(cols[1]),
      width: width(15)
    },
    {
      name: 'Symbol',
      className: classnames(styles.th, styles.thSymbol, color(cols[2])),
      title: 'Sort by symbol',
      label: `${arrow(cols[2])}[s]ymbol`,
      selected: isSelected(cols[2]),
      width: width(9)
    },
    {
      name: 'Price',
      className: classnames(styles.th, color(cols[3])),
      title: 'Sort by price',
      label: `${arrow(cols[3])}$[p]rice`,
      selected: isSelected(cols[3]),
      width: width(10)
    },
    {
      name: '1H Change',
      className: classnames(styles.th, color(cols[4])),
      title: 'Sort by 1 hour change',
      label: `${arrow(cols[4])}[1]H%`,
      selected: isSelected(cols[4]),
      width: width(8)
    },
    {
      name: '24H Change',
      className: classnames(styles.th, color(cols[5])),
      title: 'Sort by 24 hour change',
      label: `${arrow(cols[5])}[2]4H%`,
      selected: isSelected(cols[5]),
      width: width(8)
    },
    {
      name: '7D Change',
      className: classnames(styles.th, color(cols[6])),
      title: 'Sort by 7 day change',
      label: `${arrow(cols[6])}[7]D%`,
      selected: isSelected(cols[6]),
      width: width(8)
    },
    {
      name: '24H Volume',
      className: classnames(styles.th, color(cols[7])),
      title: 'Sort by volume',
      label: `${arrow(cols[7])}24H [v]olume`,
      selected: isSelected(cols[7]),
      width: width(18)
    },
    {
      name: 'Market Cap',
      className: classnames(styles.th, color(cols[8])),
      title: 'Sort by market cap',
      label: `${arrow(cols[8])}[m]arket cap`,
      selected: isSelected(cols[8]),
      width: width(18)
    },
    {
      name: 'Available Supply',
      className: classnames(styles.th, color(cols[9])),
      title: 'Sort by available supply',
      label: `${arrow(cols[9])}[a]vailable supply`,
      selected: isSelected(cols[9]),
      width: width(19)
    },
    {
      name: 'Total Supply',
      className: classnames(styles.th, color(cols[10])),
      title: 'Sort by total supply',
      label: `${arrow(cols[10])}[t]otal supply`,
      selected: isSelected(cols[10]),
      width: width(17)
    },
    {
      name: 'Last Updated',
      className: classnames(styles.th, color(cols[11])),
      title: 'Sort by last updated',
      label: `${arrow(cols[11])}last [u]pdated`,
      selected: isSelected(cols[11]),
      width: width(18)
    }
  ]

  return (
    <table className={classnames(styles.table, loading)}>
      <thead onClick={handleTableHeadClick}>
        <tr>
          {tableHeaders.map((item: any) => {
            return (
              <th
                key={item.name}
                className={item.className}
                title={item.title}
                style={{
                  width: item.width
                }}
                role="button"
                tabIndex={0}
              >
                <span data-content={item.label}>{item.name}</span>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody
        onClick={handleTableBodyClick}
        onDoubleClick={handleTableBodyDoubleClick}
      >
        {rows.length ? (
          rows.map((coin: any, i: number) => {
            const color = (value: number) => {
              return value < 0 ? styles.red : styles.green
            }

            const highlightRow = i === activeRow ? styles.highlight : undefined
            const highlightCol = (idx: number) =>
              idx === activeCol ? styles.highlight : undefined
            const updatedAt = DateTime.fromSeconds(
              Number(coin[cols[11]])
            ).toFormat('HH:mm:ss MMM d')

            const columns = [
              {
                className: classnames(
                  styles.td,
                  styles.tdRank,
                  highlightCol(0)
                ),
                title: coin[cols[0]],
                value: coin[cols[0]]
              },
              {
                className: classnames(
                  styles.td,
                  styles.tdName,
                  highlightCol(1)
                ),
                title: coin[cols[1]],
                value: coin[cols[1]]
              },
              {
                component: 'th',
                className: classnames(
                  styles.td,
                  styles.tdSymbol,
                  highlightCol(2)
                ),
                title: coin[cols[2]],
                value: coin[cols[2]]
              },
              {
                className: classnames(styles.td, styles.cyan, highlightCol(3)),
                title: coin[cols[3]],
                value: loading ? (
                  <Pinwheel />
                ) : (
                  commafy(coin[cols[3]], priceDecimals)
                )
              },
              {
                className: classnames(
                  styles.td,
                  color(coin.percentChange1H),
                  highlightCol(4)
                ),
                title: coin[cols[4]],
                value: coin[cols[4]].toFixed(2)
              },
              {
                className: classnames(
                  styles.td,
                  color(coin.percentChange24H),
                  highlightCol(5)
                ),
                title: coin[cols[5]],
                value: coin[cols[5]].toFixed(2)
              },
              {
                className: classnames(
                  styles.td,
                  color(coin.percentChange7D),
                  highlightCol(6)
                ),
                title: coin[cols[6]],
                value: coin[cols[6]].toFixed(2)
              },
              {
                className: classnames(styles.td, highlightCol(7)),
                title: coin[cols[7]],
                value: commafy(coin[cols[7]])
              },
              {
                className: classnames(styles.td, highlightCol(8)),
                title: coin[cols[8]],
                value: commafy(coin[cols[8]])
              },
              {
                className: classnames(styles.td, highlightCol(9)),
                title: coin[cols[9]],
                value: commafy(coin[cols[9]])
              },
              {
                className: classnames(styles.td, highlightCol(10)),
                title: coin[cols[10]],
                value: commafy(coin[cols[10]])
              },
              {
                className: classnames(styles.td, highlightCol(11)),
                title: coin[cols[11]],
                value: updatedAt
              }
            ]

            return (
              <tr key={coin.name} className={highlightRow}>
                {columns.map((item: any, i: number) => {
                  const props = {
                    className: item.className,
                    style: {
                      width: tableHeaders[i].width
                    },
                    title: item.title
                  }
                  if (item.component === 'th') {
                    return (
                      <th {...props}>
                        {blankChar}
                        {item.value}
                      </th>
                    )
                  }

                  return (
                    <td {...props}>
                      {blankChar}
                      {item.value}
                    </td>
                  )
                })}
              </tr>
            )
          })
        ) : (
          <tr>
            <td className={classnames(styles.fetching)} colSpan="3">
              fetching...
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
