import { useState, useEffect, useRef } from 'react'
import shortcuts from 'src/utils/shortcuts'
import orderBy from 'lodash/orderBy'
import keyboard from 'src/utils/keyboard'
import { apiUrl } from 'src/config'
import useInterval from 'src/hooks/useInterval'

function useHome () {
  const [data, setData] = useState<any[]>(() => {
    try {
      let cached = localStorage.getItem('data')
      if (cached) {
        cached = JSON.parse(cached)
        if (Array.isArray(cached)) {
          return cached
        } else {
          return []
        }
      } else {
        return []
      }
    } catch (err) {
      return []
    }
  })
  const [rows, setRows] = useState<any[]>(() => {
    return data.slice(0, 100)
  })
  const [activeRow, setActiveRow] = useState<number>(0)
  const [activeCol, setActiveCol] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('rank')
  const [sortDesc, setSortDesc] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(100)
  const [pageCount, setPageCount] = useState<number>(0)
  const [fetching, setFetching] = useState<boolean>(true)
  const [rowUrl, setRowUrl] = useState<string>('')

  const nameToSlug = (name: string) => {
    return name.toLowerCase().replace(/[^a-zA-Z0-9]/gi, '-')
  }
  const openLink = () => {
    if (rowUrl) {
      window.open(rowUrl, '_blank')
    }
  }

  const poll = async () => {
    try {
      const res = await fetch(`${apiUrl}/coins`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const csv = await res.json()
      const data = []
      let headers = csv[0]
      for (let record of csv.slice(1)) {
        const obj = {}
        for (let i = 0; i < headers.length; i++) {
          const key = headers[i]
          obj[key] = record[i]
        }
        data.push(obj)
      }
      setData(data)
    } catch (err) {
      console.error(err)
    }
  }

  const interval = 10 * 1e3
  useInterval(poll, interval)

  useEffect(() => {
    setPageCount((data.length / perPage) | 0)
  }, [data, perPage])

  useEffect(() => {
    const update = async () => {
      setFetching(true)
      await poll()
      setFetching(false)
    }
    update()
  }, [setData])

  useEffect(() => {
    try {
      if (data.length) {
        localStorage.setItem('data', JSON.stringify(data))
      }
    } catch (err) {
      console.error(err)
    }
  }, [data])

  useEffect(() => {
    const start = page * perPage
    const end = page * perPage + perPage
    const slice = data.slice(start, end)
    const sorted = orderBy(slice, [sortBy], [sortDesc ? 'desc' : 'asc'])
    setRows(sorted)
  }, [data, page, perPage, sortBy, sortDesc])

  const sort = (col: string) => {
    return () => {
      let desc = !sortDesc
      if (sortBy !== col) {
        desc = false
      }

      setSortBy(col)
      setSortDesc(desc)
    }
  }

  useEffect(() => {
    const minX = 0
    const maxX = 10
    const minY = 0
    const maxY = rows.length - 1

    const scrollTable = (height: number) => {
      const tbody = document.querySelector('#tableContainer') as any
      tbody.scrollTo(0, height)
    }
    const scrollToPageRowIndex = (idx: number) => {
      const height = getTrHeight() * idx
      scrollTable(height)
    }
    const scrollDownBy = (y: number) => {
      const height = getScrollTopOffset()
      const offset = getTrHeight() + y
      scrollTable(height + offset)
    }
    const scrollUpBy = (y: number) => {
      const height = getScrollTopOffset()
      const offset = getTrHeight() + y
      scrollTable(height - offset)
    }

    const getTrHeight = () => {
      const tr = document.querySelector('table tbody tr:first-child') as any
      const trHeight = tr.getBoundingClientRect().height
      return trHeight
    }
    const getVisibleRowsCount = () => {
      return Math.floor(getRowsHeight())
    }

    const getRowsHeight = () => {
      const tbody = document.querySelector('#tableContainer') as any
      const trHeight = getTrHeight()
      const tbodyHeight = tbody.getBoundingClientRect().height
      const rowsHeight = tbodyHeight / trHeight
      return rowsHeight
    }

    const getScrollTopOffset = () => {
      const tbody = document.querySelector('#tableContainer') as any
      return tbody.scrollTop
    }

    const getFirstVisibleRowIndex = () => {
      const trHeight = getTrHeight()
      const scrollTop = getScrollTopOffset()
      return Math.ceil(scrollTop / trHeight)
    }
    const getVisibleRowIndex = () => {
      const firstRowIndex = getFirstVisibleRowIndex()
      const currentIndex = activeRow
      const idx = currentIndex - firstRowIndex + 1
      return idx
    }
    const isLastVisibleRowIndex = () => {
      const rowsHeight = getRowsHeight()
      const currentRow = getVisibleRowIndex() + 3
      return currentRow >= rowsHeight
    }
    const isFirstVisibleRowIndex = () => {
      const currentRow = getVisibleRowIndex()
      return currentRow <= 1
    }
    const moveRight = () => {
      setActiveCol(Math.min(activeCol + 1, maxX))
    }
    const prevPage = () => {
      setPage(Math.max(page - 1, 0))
    }
    const nextPage = () => {
      setPage(Math.min(page + 1, pageCount))
    }
    const moveLeft = () => {
      setActiveCol(Math.max(activeCol - 1, minX))
    }
    const moveDown = () => {
      const isLast = isLastVisibleRowIndex()
      if (isLast) {
        scrollDownBy(1)
      }
      setActiveRow(Math.min(activeRow + 1, maxY))
    }
    const moveUp = () => {
      const isFirst = isFirstVisibleRowIndex()
      if (isFirst) {
        scrollUpBy(1)
      }
      setActiveRow(Math.max(activeRow - 1, minY))
    }
    const pageDown = () => {
      const idx = Math.min(
        activeRow + getVisibleRowsCount() - 1,
        rows.length - 1
      )
      const tr = document.querySelector(
        `table tbody tr:nth-child(${idx})`
      ) as any
      const offsetTop = Math.max(
        tr.offsetTop - getTrHeight() * getVisibleRowIndex(),
        getScrollTopOffset()
      )
      setActiveRow(idx)
      scrollTable(offsetTop)
    }
    const pageUp = () => {
      const idx = Math.max(activeRow - getVisibleRowsCount(), 0)
      const tr = document.querySelector(
        `table tbody tr:nth-child(${idx + 1})`
      ) as any
      const offsetTop = Math.max(
        tr.offsetTop - getTrHeight() * getVisibleRowIndex(),
        0
      )
      setActiveRow(idx)
      scrollTable(offsetTop)
    }
    const firstPage = () => {
      setPage(0)
    }
    const lastPage = () => {
      setPage(pageCount)
    }
    const movePageFirstRow = () => {
      setActiveRow(0)
      scrollToPageRowIndex(0)
    }
    const movePageLastRow = () => {
      setActiveRow(rows.length - 1)
      scrollToPageRowIndex(rows.length)
    }
    const scrollToVisibleRowIndex = (idx: number) => {
      const firstIdx = getFirstVisibleRowIndex()
      const scrollToIdx = Math.min(firstIdx + idx, rows.length - 1)
      setActiveRow(scrollToIdx)
    }
    const movePageVisibleMiddle = () => {
      const rowsHeight = getRowsHeight()
      const idx = Math.floor(rowsHeight / 2)
      scrollToVisibleRowIndex(idx)
    }
    const movePageVisibleFirst = () => {
      scrollToVisibleRowIndex(0)
    }
    const movePageVisibleLast = () => {
      const rowsHeight = getVisibleRowsCount()
      scrollToVisibleRowIndex(rowsHeight - 3)
    }

    const help = () => {
      alert('coming soon')
    }

    const search = () => {
      alert('coming soon')
    }

    const favorites = () => {
      alert('favorites')
    }

    const portfolio = () => {
      alert('portfolio')
    }

    const cb = (fn: any) => {
      return (event: any, key: string) => {
        event.preventDefault()
        fn()
      }
    }

    const keybindings = {
      move_up: cb(moveUp),
      move_down: cb(moveDown),
      scroll_left: cb(moveLeft),
      scroll_right: cb(moveRight),
      previous_page: cb(prevPage),
      next_page: cb(nextPage),
      page_down: cb(pageDown),
      page_up: cb(pageUp),
      sort_column_rank: cb(sort('rank')),
      sort_column_name: cb(sort('name')),
      sort_column_symbol: cb(sort('symbol')),
      sort_column_price: cb(sort('price')),
      sort_column_market_cap: cb(sort('marketCap')),
      sort_column_24h_volume: cb(sort('volume24H')),
      sort_column_1h_change: cb(sort('percentChange1H')),
      sort_column_24h_change: cb(sort('percentChange24H')),
      sort_column_7d_change: cb(sort('percentChange7D')),
      sort_column_total_supply: cb(sort('totalSupply')),
      sort_column_available_supply: cb(sort('availableSupply')),
      sort_column_last_updated: cb(sort('lastUpdated')),
      help: cb(help),
      open_search: cb(search),
      toggle_favorite: cb(favorites),
      toggle_portfolio: cb(portfolio),
      first_page: cb(firstPage),
      last_page: cb(lastPage),
      move_down_or_next_page: cb(moveDown),
      move_to_page_first_row: cb(movePageFirstRow),
      move_to_page_last_row: cb(movePageLastRow),
      move_to_page_visible_middle_row: cb(movePageVisibleMiddle),
      move_to_page_visible_first_row: cb(movePageVisibleFirst),
      move_to_page_visible_last_row: cb(movePageVisibleLast),
      open_link: cb(openLink)
    }

    for (const key in shortcuts) {
      const cb = keybindings[shortcuts[key]]
      if (cb) {
        keyboard.on(key, cb)
      }
    }

    return () => {
      for (const key in shortcuts) {
        const cb = keybindings[shortcuts[key]]
        if (cb) {
          keyboard.off(key, cb)
        }
      }
    }
  }, [
    rows,
    setRows,
    activeRow,
    setActiveRow,
    activeCol,
    setActiveCol,
    pageCount
  ])

  const entryToCsv = (entry: any) => {
    const line = [
      entry.rank,
      entry.name,
      entry.symbol,
      entry.price,
      entry.percentChange1H,
      entry.percentChange24H,
      entry.percentChange7D,
      entry.volume24H,
      entry.marketCap,
      entry.availableSupply,
      entry.totalSupply,
      entry.lastUpdated
    ]

    return line.join(',')
  }

  useEffect(() => {
    if (rows[activeRow]) {
      const name = nameToSlug(rows[activeRow].name)
      const url = `https://www.coingecko.com/en/coins/${name}`
      setRowUrl(url)
    }

    const cb = (event: any) => {
      let selection = document.getSelection().toString()
      if (!selection) {
        const entry = rows[activeRow]
        if (entry) {
          event.preventDefault()
          selection = entryToCsv(entry)
          event.clipboardData.setData('text/plain', selection)
        }
      }
    }

    document.addEventListener('copy', cb)
    return () => document.removeEventListener('copy', cb)
  }, [rows, activeRow])

  const cols = [
    'rank',
    'name',
    'symbol',
    'price',
    'percentChange1H',
    'percentChange24H',
    'percentChange7D',
    'volume24H',
    'marketCap',
    'availableSupply',
    'totalSupply',
    'lastUpdated'
  ]

  return {
    data,
    setData,
    rows,
    cols,
    setRows,
    activeRow,
    setActiveRow,
    activeCol,
    setActiveCol,
    sortBy,
    setSortBy,
    sort,
    sortDesc,
    setSortDesc,
    page,
    setPage,
    perPage,
    setPerPage,
    pageCount,
    setPageCount,
    fetching,
    rowUrl,
    openLink
  }
}

export default useHome
