import React, { useState, useEffect } from 'react'
import useHome from 'src/hooks/useHome'
import Meta from 'src/components/Meta'
import TextLogo from 'src/components/TextLogo'
import Link from 'src/components/Link'
import Table from 'src/components/Table'
import Footer from 'src/components/Footer'
import styles from './Home.module.css'

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <TextLogo />
        </div>
        <div className={styles.headerRight}>
          <code title="Access cointop.sh via SSH">$ ssh cointop.sh</code>
          <Link href="/cli">Install CLI</Link>
        </div>
      </div>
    </header>
  )
}

const IndexPage = () => {
  const {
    sortBy,
    sort,
    sortDesc,
    activeRow,
    setActiveRow,
    activeCol,
    page,
    pageCount,
    cols,
    rows,
    fetching,
    rowUrl,
    openLink
  } = useHome()

  const onRowClick = (idx: number) => {
    setActiveRow(idx)
  }

  const onRowDoubleClick = (idx: number) => {
    openLink()
  }

  const onColClick = (idx: number) => {
    sort(cols[idx])()
  }

  return (
    <main className={styles.page}>
      <Meta title="cointop - coin tracking for hackers" />
      <Header />
      <div id="tableContainer" className={styles.tableContainer}>
        <Table
          rows={rows}
          cols={cols}
          sortBy={sortBy}
          sortDesc={sortDesc}
          onRowClick={onRowClick}
          onRowDoubleClick={onRowDoubleClick}
          onColClick={onColClick}
          activeRow={activeRow}
          activeCol={activeCol}
          fetching={fetching}
        />
      </div>
      <Footer page={page} pageCount={pageCount} openUrl={rowUrl} />
    </main>
  )
}

export default IndexPage
