import React, { useEffect, useRef } from 'react'

type Callback = () => void

const useInterval = (callback: Callback, delay: number) => {
  const savedCallback = useRef<Callback>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval
