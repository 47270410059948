import React from 'react'
import styles from 'src/components/Home/Home.module.css'

const Footer = (props: any) => {
  const { page, pageCount, openUrl } = props

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.footerContent}>
          {/*[?]Help [/]Search [F]Favorites [P]Portfolio*/}
          [← →]Page {page + 1}/{pageCount + 1}{' '}
          {openUrl ? <>[O]pen {openUrl}</> : null}
        </div>
      </div>
    </footer>
  )
}

export default Footer
